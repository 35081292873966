import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import voca from 'voca';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { CarouselTextFourCardsItem, CarouselTextFourCardsModel } from '@model/carousel-text-four-cards.model';
import { useCarousel } from '@hooks/useCarousel';
import { useColors } from '@hooks/useColors';
import { getFieldLink } from '@utils/functions/getFieldLink';
import { parseHTML } from '@utils/functions/parseHTML';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { getWellSortedExposedViewResult } from '@utils/functions/getWellSortedExposedViewResults';

// Can't work with Embla
// const Box = dynamic(() => import('@mui/material/Box'));

const Grid = dynamic(() => import('@mui/material/Grid'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const CustomCard = dynamic(() => import('@atoms/CustomCard/CustomCard'));
const LinkChildren = dynamic(() => import('@atoms/LinkChildren/LinkChildren'));
const CustomButton = dynamic(() => import('@atoms/CustomButton/CustomButton'));
const FeaturedProjectsTitle = dynamic(
  () => import('@molecules/HomepageTabPanel/FeaturedProjectsTitle/FeaturedProjectsTitle')
);
const CarouselButtons = dynamic(() => import('@molecules/CarouselActions/CarouselButtons'));

/** Exposed View - behaviour carousel-small-cards */
const CarouselTextFourCardsParagraph = (props: FieldParagraphProps) => {
  const { fieldParagraph } = props;

  const getCardVariant = (typeId: string, field_machine_name = 'video') => {
    switch (typeId) {
      case 'discoveries--block_by_type':
        if (field_machine_name === 'podcast') {
          return 'podcast-card';
        }
        if (field_machine_name === 'video') {
          return 'video-card';
        }

      case 'esgs--block':
        return 'ratings-card';

      case 'sdgs--block_business_area':
        return 'sdg-card';

      case 'podcasts--block_by_playlist':
        return 'podcast-card';

      case 'podcasts--block_platforms':
        return 'padcast-platform-card';

      default:
        return 'video-card';
    }
  };

  // const {
  //   mappedData: { data, isLoaded },
  // } = useMapper<any, CarouselTextFourCardsModel>({
  //   mockDataUrl: fieldParagraph.mockDataUrl || '/data/carousel-text-video.json',
  //   backendData: fieldParagraph,
  //   realDataMapper: (setData, backendData) => {
  //     const isPlatformPodcastCarousel = backendData.view.id == 'podcasts--block_platforms';

  //     setData({
  //       title: backendData.field_title,
  //       description: backendData?.field_description?.value,
  //       cta: getFieldLink(backendData.field_cta),
  //       colorVariant: backendData.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
  //       direction: backendData.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
  //       transition: backendData.behavior_settings?.style_options?.transition?.css_class,
  //       carouselLabel: backendData?.field_overline,

  //       items: isPlatformPodcastCarousel
  //         ? backendData.view.results[0]?.field_links?.map((item) => ({
  //           label: capitalize(item?.options?.attributes?.podcast_link_type),

  //           variant: getCardVariant(backendData.view.id, item?.field_category?.field_machine_name),
  //           url: item?.uri,
  //           isExternal: true,
  //           target: '_blank',
  //           podcastIcon: item?.options?.attributes?.podcast_link_type,
  //         }))
  //         : backendData.view.results.map((item) => ({
  //           title: item.title,
  //           variant: getCardVariant(backendData.view.id, item?.field_category?.field_machine_name),
  //           url: item.path?.alias,
  //           isExternal: false,
  //           caption: item?.field_country,
  //           imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
  //           imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url),
  //         })),
  //       anchorData: backendData.behavior_settings?.style_options?.toc?.label || '',
  //     });
  //   },
  // });

  const isPlatformPodcastCarousel = fieldParagraph?.view?.id == 'podcasts--block_platforms';

  const data = useMemo(() => {
    const newData: CarouselTextFourCardsModel = {
      title: fieldParagraph.field_title,
      description: fieldParagraph?.field_description?.value,
      cta: getFieldLink(fieldParagraph.field_cta),
      colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
      direction: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
      transition: fieldParagraph.behavior_settings?.style_options?.transition?.css_class,
      carouselLabel: fieldParagraph?.field_overline,

      items: isPlatformPodcastCarousel
        ? fieldParagraph.view.results[0]?.field_links?.map((item) => ({
            label: voca.capitalize(item?.options?.attributes?.podcast_link_type),

            variant: getCardVariant(fieldParagraph.view.id, item?.field_category?.field_machine_name),
            url: item?.uri,
            isExternal: true,
            target: '_blank',
            podcastIcon: item?.options?.attributes?.podcast_link_type,
          }))
        : // @TODO: remove getWellSortedExposedViewResult after sorting fix on Drupal
          getWellSortedExposedViewResult(fieldParagraph).map((item) => ({
            title: item.title,
            variant: getCardVariant(fieldParagraph.view.id, item?.field_category?.field_machine_name),
            url: item.path?.alias,
            isExternal: false,
            caption: item?.field_country,
            imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
            imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url),
          })),
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
      itemsToDisplay: fieldParagraph?.field_view?.resourceIdObjMeta?.items_to_display || '50',
    };

    return newData;
  }, [fieldParagraph, isPlatformPodcastCarousel]);

  const { emblaApi, emblaRef, selectedIndex, scrollSnaps } = useCarousel({
    slidesToScroll: 2,
  });

  const { textColor } = useColors(data?.colorVariant);

  const groupedItems = useMemo(() => {
    if (!data) return [];

    const items = [];

    data.items?.slice(0, parseInt(data.itemsToDisplay, 10))?.forEach((item, index) => {
      if (!items[Math.floor(index / 2)]) {
        items.push([item]);
        return;
      }

      items[Math.floor(index / 2)].push(item);
    });

    return items as CarouselTextFourCardsItem[][];
  }, [data]);

  const showButtons = useMemo(() => data?.items?.length > 4, [data?.items]);

  if (!data) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      colorVariant={data.colorVariant}
      linesDirection={data.direction}
      transition={data.transition}
      py={{ xs: theme.spacing(80), lg: theme.spacing(104) }}
    >
      <GridContainer>
        <Row position="relative" rowGap={theme.spacing(40)} alignItems="center">
          <Grid item xs={0} lg={1} display={{ xs: 'none', lg: 'block' }}></Grid>
          <Grid item xs={12} lg={5} sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
            <Typography color={textColor} variant={'h3'}>
              {data.title}
            </Typography>
            <Typography color={textColor} component="div" mt={theme.spacing(24)} variant="subtitle">
              {parseHTML(data.description)}
            </Typography>
            {data?.cta?.url && (
              <CustomButton
                text={data.cta.label}
                href={data.cta.url}
                target={data.cta.target}
                color={data.colorVariant === 'dark' ? 'secondaryOnDark' : 'secondary'}
                external={data.cta.isExternal}
                sx={{ mt: { xs: theme.spacing(24), lg: theme.spacing(32) } }}
                variant="large"
                component="a"
              />
            )}
          </Grid>
          <Grid overflow="hidden" paddingTop={theme.spacing(40)} marginTop={theme.spacing(-40)} item xs={12} lg={6}>
            <FeaturedProjectsTitle title={data.carouselLabel} colorVariant={data.colorVariant} />
            <Box ref={emblaRef}>
              <Row flexWrap="nowrap">
                {groupedItems?.map((items, index) => (
                  <Grid
                    position="relative"
                    top={index % 2 !== 0 ? theme.spacing(-40) : 0}
                    flexShrink={0}
                    key={index}
                    item
                    mt={{ xs: theme.spacing(22), lg: theme.spacing(-24) }}
                    xs={6}
                  >
                    {items.map((item, index) => (
                      <LinkChildren
                        key={index}
                        rel={item.rel}
                        target={item.target}
                        isExternal={item.isExternal}
                        url={item.url}
                      >
                        <CustomCard
                          key={index}
                          sx={{
                            minHeight: { xs: theme.spacing(122), lg: theme.spacing(216) },
                            mt: { xs: theme.spacing(16), lg: theme.spacing(24) },
                          }}
                          variant={item.variant}
                          imageAlt={item.imageAlt}
                          imageUrl={item.imageUrl}
                          {...item}
                        />
                      </LinkChildren>
                    ))}
                  </Grid>
                ))}
              </Row>
            </Box>

            {showButtons && (
              <CarouselButtons
                sx={{ top: theme.spacing(200), right: theme.spacing(-74), pb: { xs: theme.spacing(34), lg: 0 } }}
                emblaApi={emblaApi}
                scrollSnapsLength={scrollSnaps.length}
                colorVariant={data.colorVariant}
                selectedIndex={selectedIndex}
              />
            )}
          </Grid>
        </Row>
      </GridContainer>
    </BackgroundTransitionBox>
  );
};

export default CarouselTextFourCardsParagraph;
