import { DrupalNode } from 'next-drupal';

// @TODO: remove getWellSortedExposedViewResult when fixed on Drupal
export const getWellSortedExposedViewResult = (fieldParagraph: DrupalNode) => {
  const fieldViewData = fieldParagraph?.field_view;
  const isBlockSelection = fieldViewData?.resourceIdObjMeta?.display_id === 'block_selection';
  const orderSeparator = (fieldViewData?.resourceIdObjMeta?.arguments as string)?.includes('+') ? '+' : ',';
  const resultsOrder = (fieldViewData?.resourceIdObjMeta?.arguments as string)?.split(orderSeparator);
  const results = fieldParagraph?.view?.results || [];
  const wellSortedResults =
    isBlockSelection && !!resultsOrder?.length
      ? [...results].sort((resA, resB) => {
          const resAId = `${resA?.drupal_internal__nid}`;
          const resBId = `${resB?.drupal_internal__nid}`;
          const resAIndex = resultsOrder.indexOf(resAId);
          const resBIndex = resultsOrder.indexOf(resBId);
          return resAIndex > resBIndex ? 1 : -1;
        })
      : results;
  return wellSortedResults as DrupalNode[];
};
